<!--  -->
<template>
  <div>
    <Banner :slideImgs="slideImgs"></Banner>
    <div class="content dis-flex my-container flex-x-between">
      <div>
        <img src="@/assets/edipdemic/left-img.png" alt="" />
      </div>
      <div class="introduce">
        <div class="soft-logo">疫情防控·链路管理系统</div>
        <p class="des">
          为严格执行国务院《新型冠状病毒肺炎防控方案（第九版）》，全面落实“外防输入、内防反弹”总策略和“动态清零”总方针，进一步做好新型冠状病毒肺炎防控工作。我们开发并试点了“疫情防控·链路管理系统”，有力支撑了各级防疫领导机构实时、精准、全面掌握疫情防控动态，极大帮助了防疫工作者管理流动人员的全链路疫情防控工作，协同多部门多角色工作，从卡口点、街道、社区、隔离点、疾控中心等全节点管理，对流动人员的疫情风险摸排识别、状态评估、精准分发、跟踪报备、风险移除的全链路管理。从根本上让防疫管控工作摆脱纸质台账登记、电子报表数据共享等繁杂工作流程，使工作更高效便捷、数据更实时精准、管理更科学智能。疫情防控·链路管理系统，为最后的流调溯源、社区风控、提供精准决策的数据支撑
        </p>
        <ul class="function-points dis-flex flex-wrap flex-x-between t-c">
          <li v-for="(item, i) in funs" :key="i" class="flex-four">
            <div class="icon">
              <i class="iconfont" :class="item.icon"></i>
            </div>
            <span>{{ item.title }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="content">
      <div class="dis-flex flex-x-center border-video">
        <div class="video-box" @click="showVideo = true" v-if="!showVideo">
          <img src="@/assets/edipdemic/video-bg.jpg" alt="播放链路管理视频" />
        </div>
        <div class="video-box" v-else>
          <video
            style="width: 100%; object-fit: fill;height: 100%;"
            autoplay
            controls
            src="https://fy-1256500462.cos.ap-chengdu.myqcloud.com/fy.mp4"
          ></video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner/index.vue";
export default {
  data() {
    return {
      slideImgs: [require("@/assets/edipdemic/banner.png")],
      funs: [
        {
          title: "入城管理",
          icon: "icon-zhikong",
        },
        {
          title: "点位管理",
          icon: "icon-position",
        },
        {
          title: "账户管理",
          icon: "icon-hushi1",
        },
        {
          title: "健康码状态",
          icon: "icon-erweima",
        },
        {
          title: "核酸检测状态",
          icon: "icon-tianjiajianyan",
        },
        {
          title: "隔离时长",
          icon: "icon-04SFA_linshirenwu",
        },
        {
          title: "隔离过程跟踪",
          icon: "icon-lujingweihu",
        },
        {
          title: "隔离点管理",
          icon: "icon-fangzidichan",
        },
        {
          title: "高中低风险",
          icon: "icon-alarm",
        },
        {
          title: "报警与提醒",
          icon: "icon-xiaoxi",
        },
        {
          title: "数据统计分析",
          icon: "icon-tongjibaobiao",
        },
        {
          title: "数据报表",
          icon: "icon-fuzhi-01",
        },
      ],
      showVideo: false,
    };
  },

  components: {
    Banner,
  },

  computed: {},

  mounted() {},

  methods: {},
};
</script>

<style lang='scss' scoped>
$theme-color: #00a29a;
.content {
  // background: #24242f;
  margin-top: 33px;
}
.introduce {
  margin-left: 54px;
  .soft-logo {
    color: $theme-color;
    font-size: 40px;
    font-family: HarmonyOS_Sans_SC_Black;
    font-weight: 400;
  }
  .des {
    width: 711px;
    font-size: 18px;
    color: #f0f0f0;
    line-height: 40px;
    margin: 23px 0 51px;
  }
  .function-points {
    width: 616px;
    font-size: 21px;
    height: 436px;
    align-content: space-between;

    .icon {
      background-color: $theme-color;
      width: 82px;
      height: 82px;
      line-height: 82px;
      margin: 0 auto;
      border-radius: 50%;
      margin-bottom: 10px;
      .iconfont {
        font-size: 47px;
      }
    }
  }
}
.border-video{
  width: 1413px;
  padding: 22px;
  height: 805px;
  border: 1px solid #666;
  border-radius: 14px;
  margin: 50px auto;
}
</style>